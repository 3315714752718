import './index.css'
function Footer() {
  return (
    <div>
      <div className='footer_about'>
        <h4>About the author</h4>
        <div>
          <img src={require('../../assets/imgs/logo.png')} />
          <div>
            <p>北京旅搭科技有限公司</p>
            <br />
            北京旅搭科技有限公司是中国领先的旅行社交+旅行服务的移动互联网平台，得益于“社交+服务”的核心优势，旅搭更理解年轻人的社交偏好，将复杂的旅行计划、预定服务和旅行社交，变得简单、高效和便捷。
            <br />
            <br />
            公司自主研发“旅搭”平台，平台集合旅行社交+旅行服务。旅行社交涵盖即时通信、附近的人、广场动态、“搭”服务、游记等功能。旅行服务涵盖酒店服务、特色餐饮推荐、特色景点推荐、娱乐场所推荐、加油站导航、车辆租聘、车辆维修、车辆保养、车辆美容、车辆改造、车辆救援、金融服务、保险服务等服务。
          </div>
        </div>
      </div>
      <div className='footer_getNow center_box'>
        <h4>Get the app now</h4>
        <div className='footer_getNow_test'>
          Create custom landing pages with Omega that converts more visitors
          than any website.
        </div>
        <div className='footer_getNow_img'>
          <img src={require('../../assets/imgs/App Store@2x.png')} />
          <img src={require('../../assets/imgs/Play Store@2x.png')} />
        </div>
      </div>
      <div className='footer_footer'>
        京ICP备2022033119号© 2022 北京旅搭科技有限公司
      </div>
    </div>
  )
}

export default Footer
