import './home.css'
import Myfooter from '../footer/index'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className='home'>
      <div className='home_head'>
        <div className='center_box'>
          <img src={require('../../assets/imgs/logo_title.png')} />
          <div>
            <div className='tips'>项目介绍</div>
            <div>
              <Link to='/platform'>服务介绍</Link>
            </div>
            <div className='login_btn'>
              <Link to='/contactUs'>关于我们</Link>
            </div>
          </div>
        </div>
      </div>
      <div className='home_center'>
        <div className='center_box'>
          <div className='home_center_img'>
            <img src={require('../../assets/imgs/Img 1@2x(3).png')} />
          </div>
          <div className='home_center_word'>
            <h4>Travel the world & meet new friends.</h4>
            <div className='home_center_word_center'>
              “我们应该找到能与自己深层次交流的旅行同伴，找到与自己契合的旅行路线，然后，把一切烦恼抛在脑后，用心感受，享受陌生与遥远带来的新奇与魔力。也许这样，你才会觉得心里缺失的那一片灵魂，被补齐了。
            </div>
            <div className='home_center_word_btn'>
              <img src={require('../../assets/imgs/email-84@2x.png')} />
              <span>Contact Us</span>
              <div>点击下载APP</div>
            </div>
          </div>
        </div>
      </div>
      <div className='center_tag'>
        <div className='title center_box'>
          <span>“旅搭”可在各大应用商店下载安装</span>
        </div>
        <div className='tag'>
          <img src={require('../../assets/imgs/tag1.png')} />
          <img src={require('../../assets/imgs/tag2.png')} />
          <img src={require('../../assets/imgs/tag3.png')} />
          <img src={require('../../assets/imgs/tag4.png')} />
          <img src={require('../../assets/imgs/tag5.png')} />
        </div>
      </div>
      <div className='num_box'>
        <div className='center_box'>
          <div>
            <div className='num'>50K+</div>
            <div className='label'>
              <img src={require('../../assets/imgs/font1.png')} />
            </div>
            <div className='value'>Downloads</div>
          </div>
          <div>
            <div className='num'>860</div>
            <div className='label'>
              <img src={require('../../assets/imgs/font3.png')} />
            </div>
            <div className='value'>Number of stores</div>
          </div>
          <div>
            <div className='num'>45K+</div>
            <div className='label'>
              <img src={require('../../assets/imgs/font2.png')} />
            </div>
            <div className='value'>Number of users</div>
          </div>
        </div>
      </div>

      <div className='home_tag home_tag1 '>
        <div className='center_box'>
          <div>
            <div className='title'>社交 & 游记</div>
            <div className='tag-li'>
              <div className='num'>1</div>
              <div>
                <div className='tag_tit'>附近的人 / people nearby</div>
                <div className='tag_val'>
                  用户可以通过附近的人找到一起旅行的玩伴，一起约饭，约电影，聊天交朋友等
                </div>
              </div>
            </div>
            <div className='tag-li'>
              <div className='num'>2</div>
              <div>
                <div className='tag_tit'>旅行游记 / Live chat</div>
                <div className='tag_val'>
                  发布旅行游记与大家分享旅行心得感悟，以及行程中的美食，景点，为准备前往旅行的用户提供详细攻略，并获得关注增加粉丝
                </div>
              </div>
            </div>
          </div>
          <div>
            <img src={require('../../assets/imgs/home_tag1_1.png')} />
          </div>
        </div>
      </div>
      <div className='home_tag home_tag2'>
        <img src={require('../../assets/imgs/home_tag2_1.png')} />
        <div className='center_box'>
          <div className='imgs'>
            <img
              className='leftimg'
              src={require('../../assets/imgs/home_tag2_2.png')}
            />
            <img src={require('../../assets/imgs/home_tag2_3.png')} />
          </div>
          <div>
            <div className='title'>行程 & 捡人</div>
            <div className='tag-li'>
              <div className='num'>1</div>
              <div>
                <div className='tag_tit'>自驾捡人 / Self driving carpool</div>
                <div className='tag_val'>
                  发布自驾游行程，搭几个志同道合的朋友，在降低出行成本的同时体验陌生和遥远带来的魔力
                </div>
              </div>
            </div>
            <div className='tag-li'>
              <div className='num'>2</div>
              <div>
                <div className='tag_tit'>旅行搭子 / Fellow Travelers</div>
                <div className='tag_val'>
                  在热闹繁华的旅行城市，找个饭搭子，逛街搭子，一起拼饭，一起逛景点，相互拍照是一件既节省旅行开支还身心愉悦的方式
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='home_tag home_tag3'>
        <img src={require('../../assets/imgs/platform_tag5_3.png')} />

        <div className='center_box'>
          <div>
            <div className='title'>旅行周边服务</div>
            <div className='value'>
              为旅搭用户提供旅行中的服务，包含订阅酒店，旅拍，租车，目的地周边游，活动等服务
            </div>
            <div className='tags'>
              {[
                '客栈/青旅',
                '旅拍写真',
                '租车/包车',
                '周边游',
                '酒吧娱乐',
                '活动/市集'
              ].map((item) => {
                return (
                  <div>
                    <img src={require('../../assets/imgs/yes.png')} />
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <img src={require('../../assets/imgs/home_tag3_1.png')} />
          </div>
        </div>
      </div>
      <Myfooter />
    </div>
  )
}

export default Home
