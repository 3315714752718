import {
    createBrowserRouter
} from "react-router-dom";
import Home from '../views/home'
import ContactUs from '../views/contactUs'
import Platform from '../views/platform'


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/contactUs",
        element: <ContactUs/>,
    },
    {
        path: "/platform",
        element: <Platform/>,
    }
]);

export default router