import './index.css'
import Myfooter from '../footer/index'
import { Link } from 'react-router-dom'
function Platform() {
  return (
    <div className='platform'>
      <div className='platform_head'>
        <div className='head center_box'>
          <img src={require('../../assets/imgs/logo-lvda@2x.png')} />
          <div>
            <div>
              <Link to='/'>项目介绍</Link>
            </div>
            <div className='tips'>服务介绍</div>
            <div className='login_btn'>
              <Link to='/contactUs'>关于我们</Link>
            </div>
          </div>
        </div>
        <div className='center center_box'>
          <div className='tit'>
            <h4>旅搭管理平台</h4>
            <div>
              为旅搭各区域主理人，服务供应商，俱乐部提供管理和维护的系统平台
            </div>
          </div>
          <div className='img'>
            <img
              className='platform1'
              src={require('../../assets/imgs/platform1.png')}
            />
            <img
              className='platform2'
              src={require('../../assets/imgs/platform2.png')}
            />
          </div>
        </div>
      </div>
      <div className='platform_tag1 center_box'>
        <div>
          <div className='icn'>
            <img src={require('../../assets/imgs/platform_icn1.png')} />
          </div>
          <div className='tit'>区域主理人管理端口</div>
          <div className='value'>
            公司各区域主理人可通过平台区域管理端入口对负责区域的店铺，活动，用户进行流量分配，活动和商户的推广等
          </div>
        </div>
        <div>
          <div className='icn'>
            <img src={require('../../assets/imgs/platform_icn2.png')} />
          </div>
          <div className='tit'>俱乐部管理端口</div>
          <div className='value'>
            旅行，自驾游，车友会等俱乐部会长可登陆俱乐部管理端处理会员申请，贴吧和问答的删查回答等，俱乐部会员可导出。
          </div>
        </div>
        <div>
          <div className='icn'>
            <img src={require('../../assets/imgs/platform_icn3.png')} />
          </div>
          <div className='tit'>服务商管理端口</div>
          <div className='value'>
            旅行供应商（店铺）可登录管理端管理店铺的订单，商品，处理售后问题，并可查看店铺的客户流量等。
          </div>
        </div>
      </div>
      <div className='platform_tag2'>
        <div className='center_box'>
          <div>
            <img src={require('../../assets/imgs/tag1.png')} />
          </div>
          <div>
            <img src={require('../../assets/imgs/tag2.png')} />
          </div>
          <div>
            <img src={require('../../assets/imgs/tag3.png')} />
          </div>
          <div>
            <img src={require('../../assets/imgs/tag4.png')} />
          </div>
        </div>
      </div>

      <div className='platform_tag platform_tag3'>
        <img src={require('../../assets/imgs/platform_tag3_1.png')} />
        <div className='center_box'>
          <div className=''>
            <div className='tit'>
              区域主理人
              <br />
              WEB管理系统平台
            </div>
            <div className='val'>
              区域主理人通过管理系统，对负责的区域市场进行监管，对本区域店铺申请，撤销进行管理，订单售后进行监管服务，分配和监管各服务商的客户流量
            </div>
            <div className='tags'>
              {[
                '区域服务店铺管理',
                '区域全部服务商品审核',
                '区域订单/售后监管',
                '区域客户流量分配/监管'
              ].map((item) => {
                return (
                  <div className='tag-item'>
                    <img src={require('../../assets/imgs/yes.png')} />
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <img src={require('../../assets/imgs/platform_tag3_2.png')} />
          </div>
        </div>
      </div>
      <div className='platform_tag platform_tag4'>
        <img src={require('../../assets/imgs/platform_tag4_1.png')} />
        <div className='center_box'>
          <div className='imgs'>
            <img
              className='img1'
              src={require('../../assets/imgs/platform_tag4_3.png')}
            />
            <img
              className='img2'
              src={require('../../assets/imgs/platform_tag4_2.png')}
            />
          </div>
          <div>
            <div className='tit'>
              俱乐部 <br />
              APP管理系统
            </div>
            <div className='tips'>
              <img src={require('../../assets/imgs/platform_tag4_4.png')} />
              <div className='tips-center'>
                <div className='tips-tit'>活动发布及管理</div>
                <div className='tips-val'>
                  组织及发布俱乐部内的活动，处理活动申请的人员并通知，收费活动的营销优惠卷等
                </div>
              </div>
            </div>
            <div className='tips'>
              <img src={require('../../assets/imgs/platform_tag4_5.png')} />
              <div className='tips-center'>
                <div className='tips-tit'>俱乐部日常管理</div>
                <div className='tips-val'>
                  会员管理，俱乐部会员与平台共享，贴吧，问答管理，回复，删除，置顶等
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='platform_tag platform_tag5'>
        <img src={require('../../assets/imgs/platform_tag5_3.png')} />
        <div className='center_box'>
          <div>
            <div className='tit'>
              服务管理& <br />
              酒店管理
            </div>
            <div className='val'>
              为服务供应商提供订单服务，产品管理，客服及优惠卷等管理功能，帮助提示店铺的营业外和服务
            </div>
          </div>
          <div className='imgs'>
            <img src={require('../../assets/imgs/platform_tag5_2.png')} />
            <img src={require('../../assets/imgs/platform_tag5_1.png')} />
          </div>
        </div>
      </div>
      <Myfooter />
    </div>
  )
}

export default Platform
