import './index.css'
import Myfooter from '../footer/index'
import {
  Link
} from "react-router-dom";
function ContactUs() {
    return (
      <div className="contactus">
        <div className="contactus_head">
          <div className="head center_box">
            <img src={require('../../assets/imgs/logo-lvda@2x.png')}/>
            <div>
              <div><Link to="/">项目介绍</Link></div>
              <div className="tips"><Link to="/platform">服务介绍</Link></div>
              <div className='login_btn'>
                <Link to='/contactUs'>关于我们</Link>
              </div>
            </div>
          </div>
          <div className="center center_box" >
            <img src={require('../../assets/imgs/2@2x.png')}/>
            <div>
              <h4>联系我们</h4>
              <div>申请加入我们，合作考察，请按照联系方式与我们取得联系，真诚的欢迎你们的到来</div>
            </div>
          </div>
        </div>
        <div className="contactus_callme">
          <h4>我们总是全天候为您服务。</h4>
          <div className='center_box'>
            <div className='callme_form'>
              <input placeholder='你的姓名/公司名称'/>
              <input placeholder='邮箱地址/电话'/>
              <input placeholder='主题'/>
              <textarea placeholder='内容输入' type="OVERFLOW:   hidden" />
              <div>提交</div>
            </div>
            <div className='callme_info'>
              <div className='callme_title'>联系电话：</div>
              <div className='callme_val'>座机： 010-51280866<br/>值班手机： 18210477636</div>
              <div className='callme_title'>联系邮箱：</div>
              <div className='callme_val'>E-mail：lvdadriver@sina.com</div>
              <div className='callme_title'>联系地址：</div>
              <div className='callme_val'>北京市海淀区<br/>上地信息路7号昊海大厦305室</div>
            </div>      
          </div>
        </div>
        <div className="contactus_map">
          <img src={require('../../assets/imgs/Map@2x.png')}/>
        </div>
        <Myfooter/>
      </div>
    );
  }
  
  export default ContactUs;
  